export let defaultTostStyle = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
}

export let anchorStyle = { textDecoration: "none", color: "inherit" }

export const FETCH_API = "FETCH_API"


export const hash = "OGEyZTE3MzA1N21zaGJiMjZhYzE4MDQ3ZDJmMnAxZTQwZmJqc25kMTVkOWNmY2Q3ODI="
